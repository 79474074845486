import { Spinner } from "Components/ui/Spinner";
import { useEffect, useState } from "react";
import { getAllAdminBusinessFeatures, getAllAdminFeatures } from "config/api";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "Components/ui/Accordion";
import {
  faChartPie,
  faComments,
  faList,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Features() {
  const [features, setFeatures] = useState();
  const [business, setBusiness] = useState();

  const [edit, setEdit] = useState({
    business: null,
    features: { attentions: false, clients: false, nps: false, survey: false },
  });

  function getIcon(icon) {
    const icons = {
      attentions: faComments,
      clients: faUsers,
      nps: faChartPie,
      survey: faList,
    };

    return icons[icon] || faUsers;
  }

  const { getAccessTokenSilently } = useAuth0();
  const getToken = async () => await getAccessTokenSilently();

  async function getFeatures() {
    try {
      await getToken().then(async (accessToken) => {
        await getAllAdminFeatures(accessToken)
          .then((feats) => {
            setFeatures(feats);
            getAllAdminBusinessFeatures(accessToken)
              .then((data) => {
                const businessFeatures = [];
                for (const b of data) {
                  const bFeatures = {};

                  for (const feature of feats) {
                    if (b.features.some((bf) => bf.featureId === feature.id)) {
                      bFeatures[feature.id] = true;
                    } else {
                      bFeatures[feature.id] = false;
                    }
                  }

                  businessFeatures.push({
                    id: b.id,
                    name: b.name,
                    features: bFeatures,
                  });
                }
                console.log(businessFeatures);
                setBusiness(businessFeatures);
              })
              .catch((e) =>
                console.error("[getAllAdminBusinessFeatures] Error: " + e)
              );
          })
          .catch((e) => console.error("[getAllAdminFeatures] Error: " + e));
      });
    } catch (error) {
      console.error("[getFeatures] Error: " + error);
    }
  }

  useEffect(() => {
    getFeatures();
  }, []);

  const handleEdit = (businessId, feature) => {
    const b = business.find((b) => b.id === businessId);

    if (!edit.business) {
      setEdit({
        business: b.id,
        features: { ...b.features, [feature]: !b.features[feature] },
      });
    } else
      setEdit((e) => ({
        business: b.id,
        features: { ...e.features, [feature]: !e.features[feature] },
      }));
  };

  const resetEdit = () => {
    setEdit((e) => ({
      business: null,
      features: {
        attentions: false,
        clients: false,
        nps: false,
        survey: false,
      },
    }));
  };

  return (
    <div className="flex flex-col px-12">
      <div className="client-title" style={{ padding: 0 }}>
        <h2>Permisos</h2>
      </div>
      <hr style={{ margin: 0, marginBottom: 20 }} />
      {!features?.length ? (
        <div className="flex items-center justify-center w-full h-24">
          <Spinner>Cargando...</Spinner>
        </div>
      ) : (
        <div className="mx-4">
          <Accordion type="single" collapsible className="w-full">
            {business &&
              business.map((b) => (
                <AccordionItem value={b.id}>
                  <AccordionTrigger
                    className="m-0 bg-gray-50/80"
                    onClick={() => resetEdit()}
                  >
                    <p className="m-0 ml-6 font-medium text-lg text-black">
                      {b.name}
                    </p>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="flex px-6 justify-center space-x-10 mt-6">
                      {features.map((f) => (
                        <div
                          className="flex p-3 px-6 bg-gray-100 rounded cursor-pointer"
                          onClick={() => handleEdit(b.id, f.id)}
                        >
                          <FontAwesomeIcon size="lg" icon={getIcon(f.id)} />
                          <p className="m-0 ml-2">{f.name}</p>
                          <input
                            className="ml-3 my-auto cursor-pointer"
                            checked={
                              edit.business === b.id
                                ? edit.features[f.id]
                                : b.features[f.id]
                            }
                            type="checkbox"
                          />
                        </div>
                      ))}
                    </div>
                    {edit.business === b.id && (
                      <div className="w-full mt-8">
                        <button
                          className="w-40 mx-auto px-6 py-2 text-white bg-blue-600 rounded-md"
                          onClick={() => {
                            // Save changes
                          }}
                        >
                          Guardar
                        </button>
                      </div>
                    )}
                  </AccordionContent>
                </AccordionItem>
              ))}
          </Accordion>
        </div>
      )}
    </div>
  );
}
