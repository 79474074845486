import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./Pages/PrivateRoute";
import Dashboard from "./Pages/Dashboard";
import Customers from "./Pages/Clients";
import AddOrder from "./Pages/Clients/AddClient";
import Order from "./Pages/Clients/OrderId";
import AddClient from "./Pages/Clients/AddClient";
import Client from "./Pages/Clients/ClientId";
import PageNotFound from "./Pages/PageNotFound";
import AttentionDetail from "./Pages/Attentions/AttentionDetail";
import Attentions from "./Pages/Attentions";
import SessionDetail from "./Pages/Sessions/SessionDetail";
import AuthProvider from "./AuthProvider";
import Config from "./Pages/Config";
import Stats from "./Pages/Stats";
import Quiz from "Pages/Survey";
import Login from "Pages/Login";
import Survey from "Pages/Survey";
import Home from "Pages/Home";
import { SidebarInset, SidebarProvider } from "Components/Navbar/ui/sidebar";
import { AppSidebar } from "Components/Navbar/app-sidebar";
import Admin from "Pages/Admin";
import Navbar from "Navbar";

function App() {
  return (
    <Router>
      <AuthProvider>
        <RouteWithNavbar>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Home />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Home />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Customers />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/attentions"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Attentions />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/attention/:id"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <AttentionDetail />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/customer/add"
              element={
                <PrivateRoute>
                  <AddClient />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer/:id"
              element={
                <PrivateRoute>
                  <Client />
                </PrivateRoute>
              }
            />
            <Route
              path="/session/:id"
              element={
                <PrivateRoute>
                  <SessionDetail />
                </PrivateRoute>
              }
            />

            <Route
              path="/nps"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Stats />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/stats"
              element={
                <PrivateRoute>
                  <Stats />
                </PrivateRoute>
              }
            />
            <Route
              path="/survey"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Survey />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/config"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Config />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <RouteWithNavbar>
                    <Admin />
                  </RouteWithNavbar>
                </PrivateRoute>
              }
            />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <PageNotFound />
                </PrivateRoute>
              }
            />
          </Routes>
        </RouteWithNavbar>
      </AuthProvider>
    </Router>
  );
}

function RouteWithNavbar({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default App;
