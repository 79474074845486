import { useAuth0 } from "@auth0/auth0-react";
import {
  faChartPie,
  faComments,
  faList,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Separator } from "Components/ui/Separator";
import { getBusinessFeatures } from "config/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const [features, setFeatures] = useState({
    attentions: false,
    clients: false,
    nps: false,
    survey: false,
  });

  const { getAccessTokenSilently } = useAuth0();
  const getToken = async () => await getAccessTokenSilently();

  async function getBusiness() {
    try {
      await getToken().then((accessToken) => {
        getBusinessFeatures(accessToken)
          .then((data) => {
            for (const feature of data) {
              setFeatures((f) => ({
                ...f,
                [feature]: true,
              }));
            }
            return data;
          })
          .catch((e) => console.error("[getBusiness] Error: " + e));
      });
    } catch (error) {
      console.error("[getBusiness] Error: " + error);
    }
  }

  useEffect(() => {
    getBusiness();
  }, []);

  return (
    <div>
      <main className="p-0">
        <div className="page-header p-4">
          <h1 className="page-title">Inicio</h1>
        </div>
        <hr className="ml-4 mr-6" />
        <div className="flex flex-col space-y-10 p-12">
          <div className="flex space-x-14 mx-auto">
            <div
              className={`flex flex-col p-6 max-w-[22rem] text-center ${
                features.attentions
                  ? "text-gray-800 bg-gray-300/40 border-black cursor-pointer active:bg-gray-300/60"
                  : "text-gray-400 bg-gray-300/10 border-gray-400"
              }  border border-solid rounded-md shadow-md`}
              title={!features.attentions && "Solicitar funcionalidad"}
              onClick={() => features.attentions && navigate("/attentions")}
            >
              <div className="mx-auto mt-5 mb-3">
                <FontAwesomeIcon
                  className={`p-8 py-9 ${
                    features.attentions
                      ? "border-gray-800 bg-gray-50/70"
                      : "border-gray-400 bg-gray-300/10"
                  } border border-solid rounded-full shadow-md`}
                  size="4x"
                  icon={faComments}
                />{" "}
                <h2 className="mt-3">Atenciones</h2>
              </div>
              <Separator
                className={`${
                  features.attentions ? "bg-gray-900" : "bg-gray-400"
                } my-1`}
              />
              <p className="px-3 leading-7">
                Haz un seguimiento de los clientes en espera, en atención o que
                finalizaron.
              </p>
            </div>
            <div
              className={`flex flex-col p-6 max-w-[22rem] text-center ${
                features.clients
                  ? "text-gray-800 bg-gray-300/40 border-black cursor-pointer active:bg-gray-300/60"
                  : "text-gray-400 bg-gray-300/10 border-gray-400"
              }  border border-solid rounded-md shadow-md`}
              title={!features.clients && "Solicitar funcionalidad"}
              onClick={() => features.clients && navigate("/clients")}
            >
              <div className="mx-auto mt-4 mb-3">
                <FontAwesomeIcon
                  className={`p-8 py-10 ${
                    features.clients
                      ? "border-gray-800 bg-gray-50/70"
                      : "border-gray-400 bg-gray-300/10"
                  } border border-solid rounded-full shadow-md`}
                  size="4x"
                  icon={faUsers}
                />{" "}
                <h2 className="mt-2">Clientes</h2>
              </div>
              <Separator
                className={`${
                  features.clients ? "bg-gray-900" : "bg-gray-400"
                } my-1`}
              />
              <p className="px-3 leading-7">
                Lleva un listado de tus clientes, sus atenciones y encuestas.
              </p>
            </div>
          </div>
          <div className="flex space-x-14 mx-auto">
            <div
              className={`flex flex-col p-6 max-w-[22rem] text-center ${
                features.nps
                  ? "text-gray-800 bg-gray-300/40 border-black cursor-pointer active:bg-gray-300/60"
                  : "text-gray-400 bg-gray-300/10 border-gray-400"
              }  border border-solid rounded-md shadow-md`}
              title={!features.nps && "Solicitar funcionalidad"}
              onClick={() => features.nps && navigate("/nps")}
            >
              <div className="mx-auto mt-6 mb-3">
                <FontAwesomeIcon
                  className={`p-8 ${
                    features.nps
                      ? "border-gray-800 bg-gray-50/70"
                      : "border-gray-400 bg-gray-300/10"
                  } border border-solid rounded-full shadow-md`}
                  size="4x"
                  icon={faChartPie}
                />{" "}
                <h2 className="mt-3">NPS</h2>
              </div>
              <Separator
                className={`${
                  features.nps ? "bg-gray-900" : "bg-gray-400"
                } my-1`}
              />
              <p className="px-3 leading-7">
                Obtén el NPS después de las conversaciones con agentes en el
                bot.
              </p>
            </div>
            <div
              className={`flex flex-col p-6 max-w-[22rem] text-center ${
                features.survey
                  ? "text-gray-800 bg-gray-300/40 border-black cursor-pointer active:bg-gray-300/60"
                  : "text-gray-400 bg-gray-300/10 border-gray-400"
              }  border border-solid rounded-md shadow-md`}
              title={!features.survey && "Solicitar funcionalidad"}
              onClick={() => features.survey && navigate("/survey")}
            >
              <div className="mx-auto mt-6 mb-3">
                <FontAwesomeIcon
                  className={`p-8 ${
                    features.survey
                      ? "border-gray-800 bg-gray-50/70"
                      : "border-gray-400 bg-gray-300/10"
                  } border border-solid rounded-full shadow-md`}
                  size="4x"
                  icon={faList}
                />{" "}
                <h2 className="mt-3">Encuestas</h2>
              </div>
              <Separator
                className={`${
                  features.survey ? "bg-gray-900" : "bg-gray-400"
                } my-1`}
              />
              <p className="px-3 leading-7">
                Realiza encuestas a tus usuarios y analiza los gráficos con sus
                respuestas.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
