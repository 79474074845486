import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Navbar from "../../Navbar";
import "../../css/Order.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getCustomer, getCustomerSessions } from "../../config/api";
import ClientTable from "../../Components/ClientTable";
import moment from "moment";
import { CustomerData } from "../../Components/CustomerData";
import Pagination from "../../Components/Pagination";
import { useAuth0 } from "@auth0/auth0-react";
import { parseChannel } from "config/utils";

function Client() {
  const { id } = useParams();

  const [client, setClient] = useState();
  const [sessions, setSession] = useState([]);

  const buildName = (client) => {
    return client.firstName + " " + (client.lastName ?? "");
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  useEffect(() => {
    getToken().then((accessToken) => {
      console.log(accessToken);
      getCustomer(id, accessToken).then((clt) => {
        setClient(clt);
      });
      getCustomerSessions(id, accessToken).then((data) => {
        const tableData = data.map((session) => ({
          ...session,
          queues: session.queues ?? 0,
          attention: session.queues ? "Agente" : "Bot",
          platform: parseChannel(session.platform),
          formattedDate: moment(session.loginTime).format("DD/MM/YYYY"),
        }));
        setSession(tableData);
      });
    });
  }, []);

  const columns = [
    ,
    {
      name: "Fecha",
      type: "col-1",
      href: "/attention/",
      content: "formattedDate",
    },
    {
      name: "Atencion",
      type: "col-1",
      href: "/attention/",
      content: "attention",
    },
    {
      name: "Colas",
      type: "col-1",
      href: "/attention/",
      content: "queues",
    },
    {
      name: "Canal",
      type: "col-1",
      href: "/attention/",
      content: "platform",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const currentList = sessions.slice(indexOfFirst, indexOfLast);

  const paginate = (page) => setCurrentPage(page);

  return (
    <>
      <Navbar />
      {client && (
        <main>
          <div className="page-header">
            <h1 className="page-title">{buildName(client)}</h1>
            <FontAwesomeIcon
              icon={faUser}
              style={{ paddingRight: "3rem", paddingBottom: "0.7rem" }}
              size="3x"
            />
          </div>
          <hr />
          <div className="page-header">
            <h2 className="client-title">Datos del cliente</h2>
          </div>

          <CustomerData customer={client} edit={true} />

          <h2 className="client-title os">Sesiones</h2>
          <ClientTable
            list={currentList}
            columns={columns}
            link={"/session/"}
          />
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={sessions.length}
            current={currentPage}
            paginate={paginate}
          />
        </main>
      )}
    </>
  );
}

export default Client;
