import {
  ArrowUpDown,
  Pencil,
  SquareArrowOutUpRight,
  Trash,
} from "lucide-react";

export const columns = [
  {
    accessorKey: "fullName",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Nombre <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("fullName")}</div>;
    },
  },
  {
    accessorKey: "email",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Email <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("email")}</div>;
    },
  },
  {
    accessorKey: "role",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Rol <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("role")}</div>;
    },
  },
  /*{
    accessorKey: "actions",
    header: "",
    cell: ({ row }) => {
      return (
        <div className="text-center">
          <a
            target="blank"
            href={"https://go.botmaker.com/#/chats/" + row.original.id}
          >
            <Pencil className="h-5 w-5 mx-4 text-gray-400" />
          </a>
          <a
            target="blank"
            href={"https://go.botmaker.com/#/chats/" + row.original.id}
          >
            <Trash className="h-5 w-5 mx-4 text-gray-400" />
          </a>
        </div>
      );
    },
  },*/
];
