import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faCogs,
  faComments,
  faHome,
  faList,
  faPowerOff,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getBusinessFeatures } from "config/api";

const Navbar = () => {
  const { logout, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const navigateInstance = useNavigate();
  const [isAllowed, setAllowed] = useState(false);
  const [user, setUser] = useState({
    name: "",
    organization: "",
    tester: false,
  });
  const [features, setFeatures] = useState({
    attentions: false,
    clients: false,
    nps: false,
    survey: false,
  });

  const navigate = (route, e) => {
    if (e) e.preventDefault();
    return navigateInstance(route);
  };

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: window.location.origin } });

  const getToken = async () => await getAccessTokenSilently();

  async function getBusiness() {
    try {
      await getToken().then((accessToken) => {
        getBusinessFeatures(accessToken)
          .then((data) => {
            for (const feature of data) {
              setFeatures((f) => ({
                ...f,
                [feature]: true,
              }));
            }
            return data;
          })
          .catch((e) => console.error("[getBusiness] Error: " + e));
      });
    } catch (error) {
      console.error("[getBusiness] Error: " + error);
    }
  }

  useEffect(() => {
    const getToken = async () =>
      await getIdTokenClaims().then((claims) => {
        console.log(claims);
        if (!claims) return;
        setUser(claims);
        if (claims["userRoles"].includes("Tester")) {
          setUser((u) => ({ ...u, tester: true }));
        }
        if (claims["userRoles"].includes("user")) {
          setAllowed(true);
        } else setAllowed(false);
      });

    getToken();
    getBusiness();
  }, [getIdTokenClaims]);

  return (
    isAllowed && (
      <nav className="navbar" id="site-left-navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-brand">
              <span>Bot</span>
              <span>Admin</span>
              <a className="os-desc">{user.organization}</a>
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#"
              className="nav-link"
              onClick={(e) => navigate("/home", e)}
            >
              <FontAwesomeIcon size="lg" icon={faHome} />
              <span className="link-text">Inicio</span>
            </a>
          </li>
          {features.attentions && (
            <li className="nav-item">
              <a
                href="#"
                className="nav-link"
                onClick={(e) => navigate("/attentions", e)}
              >
                <FontAwesomeIcon size="lg" icon={faComments} />
                <span className="link-text">Atenciones</span>
              </a>
            </li>
          )}
          {features.clients && (
            <li className="nav-item">
              <a
                href="#"
                className="nav-link"
                onClick={(e) => navigate("/clients", e)}
              >
                <FontAwesomeIcon size="lg" icon={faUsers} />
                <span className="link-text">Clientes</span>
              </a>
            </li>
          )}
          {/*
        <li className="nav-item">
          <a
            href="/sales"
            className="nav-link"
            onClick={() => navigate("/sales")}
          >
            <FontAwesomeIcon size="lg" icon={faHandshake} />
            <span className="link-text">Ventas</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/config"
            className="nav-link"
            onClick={() => navigate("/config")}
          >
            <FontAwesomeIcon size="lg" icon={faCogs} />
            <span className="link-text">Configuración</span>
          </a>
        </li>
      */}

          {features.nps && (
            <li className="nav-item">
              <a
                href="#"
                className="nav-link"
                onClick={(e) => navigate("/nps", e)}
              >
                <FontAwesomeIcon size="lg" icon={faChartPie} />
                <span className="link-text">NPS</span>
              </a>
            </li>
          )}
          {features.survey && (
            <li className="nav-item">
              <a
                href="/survey"
                className="nav-link"
                onClick={(e) => navigate("/survey", e)}
              >
                <FontAwesomeIcon size="lg" icon={faList} />
                <span className="link-text">Encuestas</span>
              </a>
            </li>
          )}
          <li className="nav-item">
            <a
              href="/config"
              className="nav-link"
              onClick={(e) => navigate("/config", e)}
            >
              <FontAwesomeIcon size="lg" icon={faCogs} />
              <span className="link-text">Configuración</span>
            </a>
          </li>
          <li className="nav-item" onClick={() => handleLogout()}>
            <a style={{ cursor: "pointer" }} className="nav-link">
              <FontAwesomeIcon size="lg" icon={faPowerOff} />
              <span className="link-text">Cerrar sesión</span>
            </a>
          </li>
        </ul>
      </nav>
    )
  );
};

export default Navbar;
