import Navbar from "../../Navbar";
import "../../css/Table.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ClientTable from "../../Components/ClientTable";
import {
  getAllCustomerAttentions,
  searchCustomerAttentions,
} from "../../config/api";
import {
  lapsedMinutes,
  parseDni,
  parseStatus,
  parseTime,
} from "../../config/utils";
import Pagination from "../../Components/Pagination";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

function Attentions() {
  const [clientList, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [filter, setFilter] = useState("none");
  const [refresh, setRefresh] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    postsPerPage: 15,
    totalPosts: 15,
  });

  const currentList = !search
    ? clientList
    : clientList.filter((d) => d.customer.firstName.match(search));

  const paginate = (currentPage) => {
    setPagination({
      ...pagination,
      currentPage,
    });
    setClients([]);
  };

  const filterFn = (filter) => {
    setFilter(filter);
    setPagination({
      ...pagination,
      currentPage: 1,
    });
    setClients([]);
  };
  const timePassedColor = (time, status) => {
    const duration = lapsedMinutes(time);
    if (status === ("IN_PROCESS" || "FINISHED")) {
      return "#8dda97";
    } else if (status === "FINISHED") {
      return "#eeeeee";
    } else if (duration < 7) {
      return "#eeeeee";
    } else if (duration < 20) {
      return "#fcc33c";
    }
    if (duration >= 20) {
      return "#fd4343";
    }
  };

  const onSearch = (text) => {
    setSearch(text);
    searchInstance(async () => {
      searchCustomerAttentions({
        take: pagination.postsPerPage,
        query: text,
        skip: (pagination.currentPage - 1) * pagination.postsPerPage,
        accessToken: getToken(),
      }).then((data) => {
        const tableData = data.items.map((client) => ({
          ...client,
          fullName:
            client.customer.firstName + " " + (client.customer.lastName ?? ""),
          waitingTime: parseTime(client.startTime, client.finishTime),
          queueName: client.queue.name,
          status: parseStatus(client.status),
          location: client.customer?.location?.name,
          agentName: client.agent?.name ?? "-",
          internalId: client.customer.internalId
            ? parseDni(client.customer.internalId)
            : "-",
          statusColor: timePassedColor(client.startTime, client.status),
        }));
        setSearchResults(tableData);
        console.log("data", tableData);
        setPagination({
          ...pagination,
          totalPosts: data.count,
        });
      });
    });
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  let timeout;

  const searchInstance = (callback) => {
    clearTimeout(timeout);
    timeout = null;
    timeout = setTimeout(callback, 2000);
  };

  useEffect(() => {
    getToken().then((accessToken) => {
      if (search === "asdasd") {
        return searchInstance(async () => {
          searchCustomerAttentions({
            take: pagination.postsPerPage,
            query: search,
            skip: (pagination.currentPage - 1) * pagination.postsPerPage,
            accessToken,
          }).then((data) => {
            const tableData = data.items.map((client) => ({
              ...client,
              fullName:
                client.customer.firstName +
                " " +
                (client.customer.lastName ?? ""),
              waitingTime: parseTime(client.startTime, client.finishTime),
              queueName: client.queue.name,
              status: parseStatus(client.status),
              location: client.customer?.location?.name,
              agentName: client.agent?.name ?? "-",
              internalId: client.customer.internalId
                ? parseDni(client.customer.internalId)
                : "-",
              statusColor: timePassedColor(client.startTime, client.status),
            }));
            setSearchResults(tableData);
            console.log("data", tableData);
            setPagination({
              ...pagination,
              totalPosts: data.count,
            });
          });
        });
      }
      getAllCustomerAttentions({
        filter,
        take: pagination.postsPerPage,
        skip: (pagination.currentPage - 1) * pagination.postsPerPage,
        accessToken,
      }).then((data) => {
        const tableData = data.items.map((client) => ({
          ...client,
          fullName:
            client.customer.firstName + " " + (client.customer.lastName ?? ""),
          waitingTime: parseTime(client.startTime, client.finishTime),
          queueName: client.queue.name,
          status: parseStatus(client.status),
          location: client.customer?.location?.name,
          agentName: client.agent?.name ?? "-",
          internalId: client.customer.internalId
            ? parseDni(client.customer.internalId)
            : "-",
          statusColor: timePassedColor(client.startTime, client.status),
        }));
        setClients(tableData);
        console.log(data);

        setPagination({ ...pagination, totalPosts: data.count });
      });
    });
  }, [pagination.currentPage, filter, refresh]);

  const columns = [
    {
      name: "Cola",
      type: "col-2",
      href: "/attention/",
      content: "queueName",
    } /*
    {
      name: "Sucursal",
      type: "col-2",
      href: "/attention/",
      content: "location",
    },*/,
    {
      name: "Tiempo total",
      type: "col-1",
      href: "/attention/",
      content: "waitingTime",
    },
    {
      name: "Cliente",
      type: "col-2",
      href: "/attention/",
      content: "fullName",
    },
    {
      name: "DNI /  CUIT",
      type: "col-1",
      href: "/attention/",
      content: "internalId",
    },
    {
      name: "Agente",
      type: "col-2",
      href: "/attention/",
      content: "agentName",
    },
    {
      name: "Estado",
      type: "col-1",
      href: "/attention/",
      content: "status",
    },
  ];

  const filters = [
    {
      name: "Ningun filtro",
      value: "none",
      disabled: false,
    },
    {
      name: "Estado:",
      value: "",
      disabled: true,
    },
    {
      name: "Esperando",
      value: "waiting",
      disabled: false,
    },
    {
      name: "Asignados",
      value: "inprocess",
      disabled: false,
    },
    {
      name: "Finalizados",
      value: "finished",
      disabled: false,
    },
  ];

  return (
    <>
      <Navbar />
      <main>
        <div className="page-header">
          <h1 className="page-title">Atenciones</h1>
          <FontAwesomeIcon
            icon={faComments}
            style={{ paddingRight: "3rem", paddingBottom: "0.7rem" }}
            size="3x"
          />
        </div>
        <hr />
        <div className="search-bar">
          <input
            className="search-bar"
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            placeholder="🔍 Buscar por cliente..."
          />
          <select
            className="filter-select"
            style={{ opacity: filter === "none" ? 0.5 : 1 }}
            value={filter}
            onChange={(e) => filterFn(e.target.value)}
          >
            {filters.map((filter) => (
              <option disabled={filter.disabled} value={filter.value}>
                {filter.name}
              </option>
            ))}
          </select>
          <Button
            className="search-button"
            onClick={() => setRefresh(!refresh)}
          >
            <FontAwesomeIcon icon={faSyncAlt} size="1x" />
          </Button>
        </div>
        <ClientTable
          link={"/attention/"}
          list={!search ? currentList : searchResults}
          columns={columns}
        />
        <Pagination
          postsPerPage={pagination.postsPerPage}
          totalPosts={pagination.totalPosts}
          current={pagination.currentPage}
          paginate={paginate}
        />
      </main>
    </>
  );
}

export default Attentions;
