import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Navbar from "../../Navbar";
import "../../css/Order.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import {
  getAttention,
  getCustomer,
  getSessionAttentions,
  transferSession,
} from "../../config/api";
import {
  capitalizeFirstLetter,
  parseChannel,
  parseStatus,
  parseTime,
  queues,
} from "../../config/utils";
import ClientTable from "../../Components/ClientTable";
import { Modal } from "../../Components/Modal";
import moment from "moment";
import { CustomerData } from "../../Components/CustomerData";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

function AttentionDetail() {
  const { id } = useParams();

  const [attention, setAttention] = useState();
  const [client, setClient] = useState();
  const [attentions, setAttentions] = useState([]);

  const [isModalOpen, setModalIsOpen] = useState(false);
  const [queue, setQueue] = useState("clcvdj8d70002jxv8exkp4i89");

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  const statusColor = (status) => {
    if (status === "WAITING") return "#fd4343";
    if (status === "IN_PROCESS") return "#fcc33c";
    return "#05934e";
  };

  const transferClient = async () => {
    console.log("transfer", queue);
    const transfer = await transferSession({
      sessionId: attention.sessionId,
      queueId: queue,
    }).then(() => toggleModal());
    console.log(queue, transfer);
    return transfer;
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getToken().then((accessToken) => {
      getAttention(id, accessToken).then((data) => {
        console.log(data);
        setAttention({
          ...data,
          duration: parseTime(
            data.assignTime,
            data.status === "CANCELED" ? data.assignTime : data.finishTime
          ),
          formattedDate: moment(data.startTime).format("DD/MM/YYYY - HH:mm"),
        });
        console.log("duration", data.assignTime);
        getCustomer(data.customer.id, accessToken).then((customer) => {
          console.log(data);
          setClient(customer);
        });
        getSessionAttentions(data.sessionId, accessToken).then((att) => {
          const tableData = att.items
            .map((attention) => ({
              ...attention,
              duration: parseTime(attention.assignTime, attention.finishTime),
              queueName: attention.queue.name,
              agentName: attention.agent?.name,
              status: parseStatus(attention.status),
              formattedDate: moment(attention.startTime).format("DD/MM/YYYY"),
              statusColor: statusColor(attention.status),
            }))
            .filter((data) => data.id !== id);
          setAttentions(tableData);
          console.log("data", tableData);
        });
      });
    });
  }, [id]);

  const columns = [
    ,
    {
      name: "Fecha",
      type: "col-1",
      href: "/customer/",
      content: "formattedDate",
    },
    {
      name: "Cola",
      type: "col-1",
      href: "/customer/",
      content: "queueName",
    },
    {
      name: "Duración",
      type: "col-1",
      href: "/customer/",
      content: "duration",
    },
    {
      name: "Agente",
      type: "col-2",
      href: "/customer/",
      content: "agentName",
    },
    {
      name: "Estado",
      type: "col-1",
      href: "/customer/",
      content: "status",
    },
    {
      name: "Tipificacion",
      type: "col-2",
      href: "/customer/",
      content: "endReason",
    },
  ];
  return (
    <>
      <Navbar />
      {attention && (
        <main>
          {isModalOpen && (
            <TransferModal
              queues={queues.map((q) => ({
                ...q,
                actual: q.id === attention.queueId,
              }))}
              onNewQueue={(e) => setQueue(e)}
              onActionButton={transferClient}
              toggleModal={toggleModal}
            />
          )}
          <div className="page-header">
            <h1 className="page-title">Atención</h1>
            <FontAwesomeIcon
              icon={faComments}
              style={{ paddingRight: "3rem", paddingBottom: "0.7rem" }}
              size="3x"
            />
          </div>
          <hr />

          <div className="client-title">
            <h2>Datos de atención</h2>
            <div className="client-actions">
              <Button
                disabled={!(attention.botmakerId ?? client?.botmakerId)}
                onClick={() =>
                  window.open(
                    "https://go.botmaker.com/#/chats/" +
                      (attention.botmakerId ?? client.botmakerId)
                  )
                }
              >
                Ir a la conversación
              </Button>
              <Button
                onClick={() => toggleModal()}
                style={{ backgroundColor: "green" }}
              >
                Transferir
              </Button>
            </div>
          </div>

          <div className="client-data" style={{ borderLeftColor: "#fcc33c" }}>
            <div>
              <label id="nombre">Agente</label>
              <input
                disabled={true}
                value={attention.agent?.name ?? "-"}
              ></input>
            </div>
            <div>
              <label id="apellido">Cola</label>
              <input disabled={true} value={attention.queue.name} />
            </div>
            <div>
              <label id="sucursal">Fecha</label>
              <input disabled={true} value={attention.formattedDate} />
            </div>
            <div>
              <label id="canal">Canal</label>
              <input
                disabled={true}
                value={capitalizeFirstLetter(
                  parseChannel(attention.session?.platform) ?? "-"
                )}
              ></input>
            </div>

            <div title={"Finalizó " + attention.assignTime}>
              <label id="email">Duración</label>
              <input disabled={true} value={attention.duration} />
            </div>
            <div>
              <label id="status">Estado</label>
              <input disabled={true} value={parseStatus(attention.status)} />
            </div>
            <div>
              <label id="cuil-cuit">Tipificación</label>
              <input disabled={true} value={attention.endReason ?? "-"} />
            </div>
            <div>
              <label id="deuda">Quién derivó</label>
              <input disabled={true} value={attention.changedById ?? "-"} />
            </div>
          </div>
          <h2 className="client-title">
            <a href={client && "/customer/" + client.id}>Datos del cliente</a>
          </h2>
          {/*edit ? (
              <a className="client-edit" onClick={() => setEdit(!edit)}>
                Editar <FontAwesomeIcon icon={faPencilAlt} />
              </a>
            ) : (
              <a className="client-edit" onClick={() => setEdit(!edit)}>
                Guardar <FontAwesomeIcon icon={faSave} />
              </a>
            )*/}
          <CustomerData customer={client} />

          <h2 className="client-title os">Atenciones Previas</h2>
          <ClientTable
            list={attentions}
            columns={columns}
            link={"/attention/"}
          />
        </main>
      )}
    </>
  );
}

function TransferModal({ toggleModal, onActionButton, queues, onNewQueue }) {
  const [queue, setQueue] = useState();
  const handleQueue = (e) => {
    setQueue(e.target.value);
    console.log(e.target.value);
    onNewQueue(e.target.value);
  };

  return (
    <Modal
      title={"Transferir atención"}
      closeButtonText={"Cerrar"}
      onRequestClose={toggleModal}
      actionButtonText={"Transferir"}
      onActionButton={() => onActionButton()}
    >
      <div style={{ width: "100%" }}>
        <label id="email">Cola de atención</label>
        <select value={queue} onChange={handleQueue} style={{ width: "100%" }}>
          {queues.map((queue) => (
            <option disabled={queue.actual} value={queue.id}>
              {queue.name}
            </option>
          ))}
        </select>
      </div>
    </Modal>
  );
}

export default AttentionDetail;
