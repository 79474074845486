import { DataTable } from "Components/DataTable";
import { Spinner } from "Components/ui/Spinner";
import { useEffect, useState } from "react";
import { columns } from "./BusinessColumns";
import { getAllAdminBusiness, requestImpersonateBusiness } from "config/api";
import { useAuth0 } from "@auth0/auth0-react";

export default function Business() {
  const [business, setBusiness] = useState();
  const [userMasterId, setUserMasterId] = useState();

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const getToken = async () => await getAccessTokenSilently();

  async function getBusiness() {
    try {
      await getToken().then((accessToken) => {
        getAllAdminBusiness(accessToken)
          .then((data) => {
            setBusiness(data);
            return data;
          })
          .catch((e) => console.error("[getBusiness] Error: " + e));
      });
    } catch (error) {
      console.error("[getBusiness] Error: " + error);
    }
  }

  useEffect(() => {
    getBusiness();
  }, []);

  useEffect(() => {
    const getToken = async () =>
      await getIdTokenClaims().then((claims) => {
        setUserMasterId(claims["masterId"]);
      });
    getToken();
  }, [getIdTokenClaims]);

  async function impersonateBusiness(businessId) {
    try {
      const accessToken = await getToken();
      const request = await requestImpersonateBusiness(accessToken, {
        businessId,
        userMasterId,
      }).catch((e) => console.error("[impersonateBusiness] Error: " + e));

      if (request) {
        await getAccessTokenSilently({ cacheMode: "off" })
          .then((a) => window.location.reload())
          .catch((err) => alert(err));
      } else {
        console.error("Error al impersonar la empresa.");
      }
    } catch (error) {
      console.error("[impersonateBusiness] Error: " + error);
    }
  }

  return (
    <div className="flex flex-col px-12">
      <div className="client-title" style={{ padding: 0 }}>
        <h2>Empresas</h2>
      </div>
      <hr style={{ margin: 0, marginBottom: 20 }} />
      {!business?.length ? (
        <div className="flex items-center justify-center w-full h-24">
          <Spinner>Cargando...</Spinner>
        </div>
      ) : (
        <DataTable
          headerClassName="bg-gray-700 text-white"
          rowClassName="bg-gray-100/80 hover:bg-gray-200/70"
          columns={columns(impersonateBusiness)}
          data={business}
        />
      )}
    </div>
  );
}
