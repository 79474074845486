const StatCard = ({ title, children, loading, style }) => {
  return (
    <div className="stats-card">
      <h4 className="text-center font-extrabold text-lg text-slate-800 drop-shadow tracking-wide mt-4">
        {title}
      </h4>
      <div
        style={{
          display: "flex",
          padding: "0.75rem",
          height: "100%",
          flexDirection: "column",
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default StatCard;
