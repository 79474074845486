import {
  BarChart as BarChartRecharts,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from "recharts";

const StackedBarChart = ({ data, values = [] }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartRecharts
        data={data}
        className="-ml-4 text-sm"
        margin={{
          right: 30,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" padding={{ left: 20, right: 10 }} />
        <YAxis />
        <Tooltip content={CustomTooltip} />
        {values.map(({ key, color, name }) => (
          <Bar
            dataKey={key}
            stackId="bar"
            name={name}
            fill={color}
            label={{ position: "top" }}
          />
        ))}
      </BarChartRecharts>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-slate-700/70 rounded-sm py-1 px-3">
        {payload.map((p) => (
          <div className="">
            <p className="text-sm inline text-gray-200 tracking-wide my-1 font-semibold">
              {p.name}:{" "}
            </p>
            <span className="text-white font-bold text-lg text-r">
              {p.value}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default StackedBarChart;
