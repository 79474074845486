import { useAuth0 } from "@auth0/auth0-react";
import ChartPie from "Components/PieChart";
import StackedBarChart from "Components/StackedBarChart";
import StatCard from "Components/StatCard";
import { Separator } from "Components/ui/Separator";
import {
  getSurveyComments,
  getSurveyCount,
  getSurveyEvolution,
  getSurveyQuestionAnswers,
} from "config/api";
import moment from "moment";
import { useEffect, useState } from "react";

export default function Satisfaction({ date, agents, queues }) {
  const [survey, setSurvey] = useState({
    answers: {
      total: 0,
      unstarted: 0,
      complete: 0,
      incomplete: 0,
    },
    evolution: [],
    comments: [],
    evaluation: [],
    quality: [],
  });

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  async function getSurvey() {
    try {
      await getToken().then(async (accessToken) => {
        setSurvey({
          answers: {
            total: 0,
            unstarted: 0,
            complete: 0,
            incomplete: 0,
          },
          evolution: [],
          comments: [],
          evaluation: {},
          quality: {},
        });

        await Promise.all([
          getSurveyCount(accessToken, {
            surveyId: "attention",
            date,
            agents,
            queues,
          }).then((data) =>
            setSurvey((s) => ({
              ...s,
              answers: {
                complete: data.complete,
                unstarted: data.notStarted,
                incomplete: data.notFinished,
                total: data.complete + data.notStarted + data.notFinished,
              },
            }))
          ),
          getSurveyComments(accessToken, {
            surveyId: "attention",
            date,
            agents,
            queues,
          }).then((data) =>
            setSurvey((s) => ({
              ...s,
              comments: data ?? {},
            }))
          ),
          getSurveyEvolution(accessToken, {
            surveyId: "attention",
            date,
            agents,
            queues,
          }).then((data) =>
            setSurvey((s) => ({
              ...s,
              evolution: data ?? [],
            }))
          ),
          getSurveyQuestionAnswers(accessToken, {
            questionId: "AtencionRecibida",
            surveyId: "attention",
            date,
            agents,
            queues,
          }).then((data) =>
            setSurvey((s) => ({
              ...s,
              evaluation: data ?? [],
            }))
          ),
          getSurveyQuestionAnswers(accessToken, {
            questionId: "CalificacionServicio",
            surveyId: "attention",
            date,
            agents,
            queues,
          }).then((data) =>
            setSurvey((s) => ({
              ...s,
              quality: data ?? [],
            }))
          ),
        ]);
      });
    } catch (error) {
      console.error("[getSurvey] Error: ", error);
    }
  }

  useEffect(() => {
    getSurvey();
  }, []);

  useEffect(() => {
    if (!survey.answers.total) return; // Avoid at first load
    const getData = setTimeout(() => {
      getSurvey();
    }, 2000);

    return () => clearTimeout(getData);
  }, [date, agents, queues]);

  const COLORS = ["#0088FE", "#ffb617", "#FF8042", "#00C49F", "#8884d8"];

  return (
    <>
      <div className="flex mx-auto">
        <StatCard title="Cantidad">
          <div className="w-full m-auto text-center">
            <div>
              <h1 className="text-7xl mt-0 mb-0">
                {Number(survey.answers.complete + survey.answers.incomplete)}
              </h1>
              <h4 className="-mt-2 leading-5 text-sm">Respuestas</h4>
            </div>
            <Separator className="bg-gray-300 my-2" />
            <div className="pt-2 text-gray-400 flex h-16 w-full">
              <div className="mt-2 w-1/3">
                <h1 className="text-2xl mt-0 mb-0">
                  {Number(survey.answers.complete)}
                </h1>
                <h4 className="-mt-5 leading-5 text-xs">Completas</h4>
              </div>
              <Separator
                orientation="vertical"
                className="bg-gray-300 mx-4 my-auto"
              />
              <div className="mt-2 w-1/3">
                <h1 className="text-2xl mt-0 mb-0">
                  {Number(survey.answers.incomplete)}
                </h1>
                <h4 className="-mt-5 leading-5 text-xs">Incompleta</h4>
              </div>
              <Separator
                orientation="vertical"
                className="bg-gray-300 mx-4 my-auto"
              />
              <div className="mt-2 w-1/3">
                <h1 className="text-2xl mt-0 mb-0">
                  {Number(survey.answers.unstarted)}
                </h1>
                <h4 className="-mt-5 leading-5 whitespace-nowrap text-xs">
                  Sin iniciar
                </h4>
              </div>
            </div>
          </div>
        </StatCard>

        <StatCard title="Evolución de respuestas">
          <div className="w-[500px] -mx-7 -mb-4 h-[250px]">
            <StackedBarChart
              values={[
                { key: "complete", color: "#00C49F", name: "Completa" },
                { key: "incomplete", color: "#ffb617", name: "Incompleto" },
                { key: "unstarted", color: "#0088FE", name: "Sin empezar" },
              ]}
              data={survey.evolution.map((v) => ({
                date: moment(v.date).format("DD-MM"),
                total: v.total,
                unstarted: v.unstarted,
                incomplete: v.incomplete,
                complete: v.complete,
                name: "Cantidad",
              }))}
            />
          </div>
        </StatCard>

        <StatCard title="Comentarios" style={{ paddingRight: 0 }}>
          <div className="max-h-64 max-w-64 overflow-x-auto w-full">
            {survey.comments?.map((e) => (
              <>
                <p className="text-sm line-clamp-3">{e.answer}</p>
                <Separator />
              </>
            ))}
          </div>
        </StatCard>
      </div>
      <div className="flex mx-auto">
        <StatCard title="Evaluación de atención">
          <ChartPie
            data={Object.keys(survey.evaluation)?.map((name, i) => ({
              name: name === "others" ? "Otros" : name,
              value: survey.evaluation[name],
              color: COLORS[i % COLORS.length],
            }))}
          />
        </StatCard>

        <StatCard title="Calidad del servicio">
          <ChartPie
            data={Object.keys(survey.quality).map((name, i) => ({
              name: name === "others" ? "Otros" : name,
              value: survey.quality[name],
              color: COLORS[i % COLORS.length],
            }))}
          />
        </StatCard>
      </div>
    </>
  );
}
