import { useState } from "react";
import "../../css/Dashboard.css";
import Users from "./Users";
import { cn } from "lib/utils";
import Business from "./Business";
import Features from "./Features";

export default function Admin() {
  const pages = {
    business: {
      id: "business",
      component: <Business />,
      name: "Empresas",
    },
    users: {
      id: "users",
      component: <Users />,
      name: "Usuarios",
    },
    permissions: {
      id: "permissions",
      component: <Features />,
      name: "Permisos",
    },
    demo: {
      id: "demo",
      component: <Users />,
      name: "Demo",
    },
  };
  const [page, setPage] = useState(pages["business"]);

  return (
    <main>
      <div className="page-header">
        <h1 className="page-title">Administrador</h1>
      </div>
      <hr />
      <div className="flex h-full -mt-2.5 ml-6">
        <div className="flex flex-col w-full min-w-40 max-w-56 pt-3 border-0 border-r border-gray-300 border-solid">
          <h3 className="mr-2 mt-6 font-medium">Configuraciones</h3>
          <div className="mt-8">
            {Object.keys(pages).map((s, i) => (
              <div
                className={cn(
                  "-mr-px p-2 my-6 border-solid border-gray-300 border border-l-0 cursor-pointer",
                  page.id === pages[s].id
                    ? "border-r-[#e7e7e7]"
                    : "shadow bg-gray-400/20",
                  i > 0 ? "" : "mt-0"
                )}
                onClick={() => setPage(pages[s])}
              >
                <p className="m-0 text-gray-700">{pages[s].name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full pt-3 pr-2 pl-6">
          {page.component}
        </div>
      </div>
    </main>
  );
}
