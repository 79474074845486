import "../../css/Dashboard.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "Components/ui/DataPicker";
import Satisfaction from "./surveys/Satisfaction";
import { cn } from "lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "Components/ui/Accordion";
import { Spinner } from "Components/ui/Spinner";
import { Checkbox } from "Components/ui/Checkbox";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadSurveyChats, getAgents, getAllQueues } from "config/api";
import { Button } from "Components/ui/Button";

function Survey() {
  const now = moment().add(1, "day").hour(0).minutes(0);
  const endOfMonth = moment()
    .date(1)
    .add(1, "month")
    .subtract(1, "day")
    .hour(0)
    .minutes(0);

  const [date, setDate] = useState({
    from: moment().date(1).toDate(),
    to: endOfMonth.isAfter(now) ? now.toDate() : endOfMonth.toDate(),
  });

  const surveys = {
    attention: {
      id: "attention",
      component: Satisfaction,
      name: "Satisfacción",
      chats: [],
    },
  };

  const [survey, setSurvey] = useState(surveys["attention"]);

  const [filters, setFilters] = useState({
    agents: [],
    queues: [],
    queueList: [],
    agentList: [],
  });

  const handleDates = (d) => {
    return setDate({
      from: d?.from ? moment(d.from).toDate() : undefined,
      to: d?.to
        ? moment(d.to).add(23, "hours").minutes(59).seconds(59).toDate()
        : undefined,
    });
  };

  const setQueue = (queue) => {
    if (queue === "clear") {
      setFilters((f) => ({ ...f, queues: [] }));
    } else if (filters.queues.find((q) => q === queue)) {
      setFilters((f) => ({
        ...f,
        queues: f.queues.filter((q) => q !== queue),
      }));
    } else {
      setFilters((f) => ({ ...f, queues: [...f.queues, queue] }));
    }
  };

  const setAgent = (agent) => {
    if (agent === "clear") {
      setFilters((f) => ({ ...f, agents: [] }));
    } else if (filters.agents.find((q) => q === agent)) {
      setFilters((f) => ({
        ...f,
        agents: f.agents.filter((q) => q !== agent),
      }));
    } else {
      setFilters((f) => ({ ...f, agents: [...f.agents, agent] }));
    }
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  async function getFilters() {
    try {
      await getToken().then((accessToken) => {
        Promise.all([getAllQueues(accessToken), getAgents(accessToken)])
          .then((data) => {
            setFilters((f) => ({
              ...f,
              queueList: data[0].map((v) => ({
                id: v.botmakerId,
                name: v.name,
              })),
              agentList: data[1].db.map(({ id, name, email }) => ({
                id,
                name,
                email,
              })),
            }));
          })
          .catch((e) => console.error("[getFilters] Error: " + e));
      });
    } catch (e) {
      console.error("[getFilters] Error: ", e);
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  async function getChats() {
    try {
      await getToken().then(async (accessToken) => {
        const url = await downloadSurveyChats(accessToken, {
          date,
          agents: filters.agents,
          queues: filters.queues,
          surveyId: survey.id,
        });
        window.open(url, "_blank");
      });
    } catch (e) {}
  }

  return (
    <main>
      <div className="page-header">
        <h1 className="page-title">Encuestas</h1>
        <div className="ml-auto flex space-x-3">
          <DatePicker date={date} setDate={handleDates} />
          <Button
            variant="outline"
            className="bg-gray-500 hover:bg-gray-600 hover:text-white mb-0"
            onClick={() => getChats()}
          >
            Descargar chats
          </Button>
        </div>
      </div>
      <hr />
      <div className="flex h-full -mt-2.5 ml-6">
        <div className="flex flex-col w-full min-w-40 max-w-64 pt-3 border-0 border-r border-gray-300 border-solid">
          <h3 className="mr-2 mt-6 font-medium">Tipos de encuestas</h3>
          <div className="mt-2">
            {Object.keys(surveys).map((s, i) => (
              <div
                className={cn(
                  "-mr-px p-2 my-6 border-solid border-gray-300 border border-l-0 cursor-pointer",
                  survey.id === surveys[s].id
                    ? "border-r-[#e7e7e7]"
                    : "shadow bg-gray-400/20",
                  i > 0 ? "" : "mt-0"
                )}
                onClick={() => setSurvey(surveys[s])}
              >
                <p className="m-0 text-gray-700">{surveys[s].name}</p>
              </div>
            ))}
          </div>
          <h3 className="mr-2 mt-8 font-medium">Filtros</h3>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1" className="w-full">
              <AccordionTrigger className="mr-6 -mt-3 hover:no-underline tracking-wider text-gray-700 border-gray-400">
                <div className="ml-2">
                  Cola de Atención
                  {!!filters.queues.length && (
                    <span className="ml-2 text-gray-300 rounded-full bg-gray-500 px-2 py-1">
                      {filters.queues.length}
                    </span>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="ml-2 mr-5 overflow-y-auto max-h-72">
                <p
                  className="mt-0 ml italic underline-offset-2 underline text-gray-400 cursor-pointer"
                  onClick={() => setQueue("clear")}
                >
                  Limpiar filtro
                </p>
                {filters.queueList.length ? (
                  filters.queueList.map((queue) => (
                    <>
                      <div
                        className="flex items-center"
                        onClick={() => setQueue(queue.id)}
                      >
                        <Checkbox
                          checked={filters.queues.includes(queue.id)}
                          id={queue.id}
                        />
                        <label className="ml-2" htmlFor={queue.id}>
                          {queue.name}
                        </label>
                      </div>
                      <hr />
                    </>
                  ))
                ) : (
                  <div className="mt-12 text-gray-500">
                    <Spinner>Cargando...</Spinner>
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger className="mr-5 -mt-2 hover:no-underline tracking-wider text-gray-700 border-gray-400">
                <div className="ml-2">
                  Agentes
                  {!!filters.agents.length && (
                    <span className="ml-2 text-gray-300 rounded-full bg-gray-500 px-2 py-1">
                      {filters.agents.length}
                    </span>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="overflow-y-auto max-h-72 flex-col flex-grow-0 mr-3 pb-2">
                <p
                  className="mt-0 ml italic underline-offset-2 underline text-gray-400 cursor-pointer"
                  onClick={() => setAgent("clear")}
                >
                  Limpiar filtro
                </p>
                <div className="flex flex-col">
                  {filters.agentList.length ? (
                    filters.agentList.map((agent) => (
                      <>
                        <div
                          className="flex items-center"
                          onClick={() => setAgent(agent.id)}
                        >
                          <Checkbox
                            checked={filters.agents.includes(agent.id)}
                            id={agent.id}
                            className=""
                          />
                          <label
                            className="ml-2"
                            title={agent.email}
                            htmlFor={agent.id}
                          >
                            <p className="inline break-words">
                              {agent.name.includes("Unknown")
                                ? agent.email
                                : agent.name}
                            </p>
                          </label>
                        </div>
                        <hr />
                      </>
                    ))
                  ) : (
                    <div className="mt-12 text-gray-500">
                      <Spinner>Cargando...</Spinner>
                    </div>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="flex flex-col w-full pt-3 pr-2 pl-6">
          {React.createElement(survey.component, {
            date,
            agents: filters.agents,
            queues: filters.queues,
          })}
        </div>
      </div>
    </main>
  );
}
export default Survey;
