import {
  BarChart as BarChartRecharts,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from "recharts";

const BarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartRecharts
        data={data}
        className="-ml-4 text-sm"
        margin={{
          top: 5,
          right: 10,
          left: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" padding={{ left: 20, right: 10 }} />
        <YAxis />
        <Tooltip content={CustomTooltip} />
        <Bar
          dataKey="count"
          stackId="bar"
          fill="#0088FE"
          label={{ position: "top" }}
        />
        <Bar
          dataKey="county"
          stackId="bar"
          fill="#ffb617"
          label={{ position: "top" }}
        />
      </BarChartRecharts>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log("payload", payload);
    return (
      <div className="bg-slate-700/70 rounded-sm px-3">
        {payload.map((p) => (
          <p className="text-sm text-gray-200 tracking-wide font-semibold pt-1 mt-0 pb-1">
            {p.payload.name}:{" "}
            <span className="text-white font-bold text-lg">{p.value}</span>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default BarChart;
