import { ArrowUpDown, User } from "lucide-react";

export const columns = (handleImpersonation) => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Nombre <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("name")}</div>;
    },
  },
  {
    accessorKey: "id",
    header: "Ingresar",
    cell: ({ row }) => {
      return (
        <div
          className="text-center cursor-pointer"
          onClick={() => handleImpersonation(row.getValue("id"))}
        >
          <User className="h-5 w-5 mx-4 text-gray-400" />
        </div>
      );
    },
  },
];
