import { faClipboardList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { queues as queuesList } from "../../../config/utils";
import { DataTable } from "Components/DataTable";
import { Button } from "Components/ui/Button";
import { Spinner } from "Components/ui/Spinner";
import { useEffect, useState } from "react";
import { columns } from "./UsersColumns";
import { createUser, getAllAdminUsers } from "config/api";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from "Components/Modal";

export default function Users() {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [agents, setAgents] = useState();
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
  });
  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  async function getUsers() {
    try {
      await getToken().then((accessToken) => {
        getAllAdminUsers(accessToken)
          .then((data) => {
            setAgents(
              data.map((agent) => ({
                ...agent,
                fullName: `${agent.firstName} ${agent.lastName ?? ""}`,
              }))
            );
            return data;
          })
          .catch((e) => console.error("[fetchOrdersQty] Error: " + e));
      });
    } catch (error) {
      console.error("[getUsers] Error: " + error);
    }
  }

  useEffect(() => {
    getUsers();
  }, [isModalOpen]);

  const toggleModal = () => setModalIsOpen(!isModalOpen);

  return (
    <div className="flex flex-col px-12">
      {isModalOpen &&
        (!newUser.email ? (
          <AddUserModal
            onActionButton={(user) =>
              createUserAccount(user, getToken, setNewUser)
            }
            toggleModal={toggleModal}
            businesses={queuesList}
          />
        ) : (
          <NewUserModal
            closeModal={() => {
              toggleModal();
              setNewUser({});
            }}
            user={newUser}
          />
        ))}
      <div className="client-title" style={{ padding: 0 }}>
        <h2>Usuarios</h2>
        <div className="client-actions">
          <Button
            onClick={() => toggleModal()}
            style={{ backgroundColor: "green" }}
          >
            <FontAwesomeIcon className="mr-1.5" icon={faPlus} /> Agregar
          </Button>
        </div>
      </div>
      <hr style={{ margin: 0, marginBottom: 20 }} />
      {!agents?.length ? (
        <div className="flex items-center justify-center w-full h-24">
          <Spinner>Cargando...</Spinner>
        </div>
      ) : (
        <DataTable
          headerClassName="bg-gray-700 text-white"
          rowClassName="bg-gray-100/80 hover:bg-gray-200/70"
          columns={columns}
          data={agents}
        />
      )}
    </div>
  );
}

const createUserAccount = async (user, getToken, setNewUser) => {
  const accessToken = await getToken();
  const queues = Object.entries(user.queues)
    .filter(([q, x]) => !!x)
    .map(([q, x]) => {
      const queue = queuesList.find((qs) => qs.name === q);
      return queue.botmakerId;
    });
  const newUser = await createUser({
    ...user,
    queues,
    accessToken,
  }).then((user) => {
    setNewUser(user.botmakerUser ?? { email: null, password: null });
  });
  return newUser;
};

function AddUserModal({ toggleModal, onActionButton, businesses }) {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businesses: businesses
      .map((queue) => ({
        [queue.name]: false,
      }))
      .reduce((r, c) => ({ ...r, ...c }), {}),
  });

  const handleInput = (e) => {
    if (e.target.type === "checkbox") {
      return setUser((u) => ({
        ...u,
        [e.target.name]: !u[e.target.name],
      }));
    }
    setUser((u) => ({ ...u, [e.target.name]: e.target.value }));
  };

  const handleBusiness = (e) => {
    setUser((u) => ({
      ...u,
      businesses: {
        ...u.businesses,
        [e.target.name]: !u.businesses[e.target.name],
      },
    }));
  };

  return (
    <Modal
      title={"Agregar usuario"}
      closeButtonText={"Cerrar"}
      onRequestClose={toggleModal}
      actionButtonText={"Agregar"}
      onActionButton={() => onActionButton(user)}
    >
      <div style={{ width: "100%" }}>
        <br />
        <label id="firstName">Nombre</label>
        <input
          value={user.firstName}
          name="firstName"
          onChange={handleInput}
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <br />
        <label id="lastName">Apellido</label>
        <input
          value={user.lastName}
          name="lastName"
          onChange={handleInput}
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <br />
        <label id="email">Email</label>
        <input
          name="email"
          value={user.email}
          onChange={handleInput}
          style={{ width: "100%" }}
        />
        <p style={{ opacity: 0.4, fontSize: 12 }}>
          La contraseña será generada automáticamente.
        </p>
      </div>

      <div>
        <label id="businesses" style={{ marginBottom: "15px" }}>
          Acceso a empresas
        </label>
        {businesses.map((business, i) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: "10px",
            }}
          >
            <input
              name={business.name}
              type="checkbox"
              checked={user.businesses[business.name]}
              onChange={handleBusiness}
              style={{
                width: "25px",
                marginTop: 1,
                alignItems: "baseline",
              }}
            />
            <p
              onClick={() =>
                handleBusiness({ target: { name: business.name } })
              }
              style={{ margin: 0 }}
            >
              {business.name}
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
}

function NewUserModal({ closeModal, user }) {
  const { email, password } = user;

  const [copy, setCopy] = useState(false);

  useEffect(() => setCopy(false), [password]);

  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    setCopy(true);
  };

  return (
    <Modal
      title={"Usuario creado"}
      closeButtonText={"Cerrar"}
      onRequestClose={() => closeModal()}
    >
      <div style={{ width: "100%" }}>
        <label id="email">Email</label>
        <input disabled value={email ?? "-"}></input>
      </div>
      <br />
      <div style={{ width: "100%" }}>
        <label id="email">Contraseña</label>
        <div style={{ display: "flex" }}>
          <input disabled value={password ?? "-"}></input>
          <div style={{ top: "30px" }}>
            <Button
              style={{ backgroundColor: "#999", margin: 10 }}
              title={"Copiar al portapapeles"}
              onClick={() => handleCopy()}
            >
              <FontAwesomeIcon icon={faClipboardList} size="1x" />
            </Button>
          </div>
        </div>
      </div>
      <p style={{ opacity: 0.4, fontSize: 12 }}>
        {!copy
          ? "Esta contraseña se envió por email al nuevo usuario. (Prontamente)"
          : "Copiado al portapapeles."}
      </p>
    </Modal>
  );
}
